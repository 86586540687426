import {Component, Input} from '@angular/core';
import {TooltipModule} from 'primeng/tooltip';
import {RippleModule} from 'primeng/ripple';
import {NgClass, NgIf, NgStyle} from '@angular/common';

@Component({
  selector: 'app-collapsible-panel',
  template: `
    <div class="panel-container">
      <div class="panel"
           [ngStyle]="{'width': isExpanded ? width : '12px'}"
           [ngClass]="{'expanded': isExpanded, 'collapsed': !isExpanded}">
        <ng-content></ng-content>
      </div>
      <div *ngIf="showToggle" pRipple class="toggle-icon-container" [style.top]="toggleYPosition" (click)="toggle()"
           [pTooltip]="isExpanded ? collapseTooltip : expandTooltip" tooltipPosition="bottom">
        <i class="pi"
           [class.pi-angle-double-left]="!isExpanded"
           [class.pi-angle-double-right]="isExpanded"></i>
      </div>
    </div>
  `,
  styles: [
    `
      .panel-container {
        position: relative;
        height: 100%;
        border-left: 1px solid;
        border-color: var(--gray-300);
      }

      .panel-container > .panel {
        // overflow: hidden;
        transition: width 0.4s cubic-bezier(0.23, 1, 0.32, 1);;
        height: 100%;
        display: block;
        // padding-left: 12px;
      }

      .panel-container > .panel.collapsed {
        // visibility: hidden;
        // display: none;
        height: 0;
        overflow: hidden;
      }

      .panel-container > .toggle-icon-container {
        left: -12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;

        color: #FFF;
        background-color: var(--primary-color);
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background-color: var(--primary-700);
        }

        cursor: pointer;
        position: absolute;
        z-index: 4;
        transform: translateY(-50%);
      }
    `
  ],
  standalone: true,
  imports: [NgStyle, NgClass, NgIf, RippleModule, TooltipModule]
})
export class CollapsiblePanelComponent {
  @Input() width?: string;
  @Input() isExpanded = true;
  @Input() toggleYPosition = '50%';
  @Input() expandTooltip?: string;
  @Input() collapseTooltip?: string;
  @Input() showToggle = true;

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
