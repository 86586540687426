import {Injectable} from '@angular/core';
import {Endpoint, EntityResourceBaseService} from '../../../shared/services/resources/entity-resource-base.service';
import {OnDemandLoader} from '../../../shared/services/resources/on-demand-resource-loader.service';
import {Observable} from 'rxjs';
import {ISkill, TSkillSave} from '../../../api/shared/app-domain/dictionaries';

@Injectable({
  providedIn: 'root',
})
@Endpoint('/api/skills')
export class SkillsResourceService extends EntityResourceBaseService<TSkillSave, ISkill> {

  @OnDemandLoader('skillSilos')
  skillSilos(): Observable<Array<string>> {
    return this.stringArrayFetchHelper('silo');
  }
}
