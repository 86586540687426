import {AfterViewChecked, Directive, EventEmitter, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[lifecycle]',
  exportAs: 'lifecycle',
  standalone: true,
})
export class LifeCycleHookDirective implements OnInit, AfterViewChecked {
  @Output() onInit: EventEmitter<void> = new EventEmitter<void>();
  @Output() onInitialized: EventEmitter<void> = new EventEmitter<void>();
  initialized = false;

  ngOnInit(): void {
    this.onInit.emit();
  }

  ngAfterViewChecked(): void {
    if (!this.initialized) {
      this.initialized = true;
      this.onInitialized.emit();
    }
  }

}
