import {NavigationEnd, Router, RouterLink, UrlCreationOptions} from '@angular/router';
import {AfterContentInit, Component, Input} from '@angular/core';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import {SharedModule} from 'primeng/api';
import {filter} from 'rxjs';
import {find} from 'lodash';
import {subscriptions} from '../util/util';

export interface IRouterLinkItem {
  label: string;
  routerLink: any;
  options?: UrlCreationOptions;
}

@Component({
  selector: 'app-nav-dropdown',
  template: `
    <span class="p-float-label">
      <p-dropdown [(ngModel)]="item"
                  [autoDisplayFirst]="false"
                  [options]="items"
                  (ngModelChange)="navigate()"
                  [style]="{minWidth}">
      </p-dropdown>
      <label>{{ label }}</label>
    </span>
  `,
  imports: [
    DropdownModule,
    FormsModule,
    RouterLink,
    SharedModule
  ],
  standalone: true
})
export class NavDropdownComponent implements AfterContentInit {
  @Input() label = 'Scope';
  @Input() items: Array<IRouterLinkItem> = [];
  @Input() minWidth = '160px';
  item?: IRouterLinkItem;

  constructor(private router: Router) {
    subscriptions().add(
      this.router.events
        .pipe(filter((event: any) => event instanceof NavigationEnd))
        .subscribe((e) => {
          this.update();
        })
    );
  }

  ngAfterContentInit(): void {
    this.update();
  }

  navigate(): void {
    this.router.navigate(this.item?.routerLink, this.item?.options);
  }

  private update(): void {
    this.item = find(this.items, (item) => this.isActive(item));
  }

  isActive(item: IRouterLinkItem): boolean {
    return this.router.isActive(this.router.createUrlTree(item.routerLink), false);
  }
}
