import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import {CommonPageService, ICommonPageEvent, PageHeaderTemplateEvent, PageTitleEvent} from './common-page.service';
import {PrimeTemplate} from 'primeng/api';
import {COMMON_PAGE_HEADER_TEMPLATES, ICommonPageHeaderTemplates} from './common-page.component';
import {subscriptions} from '../../util/util';

@Component({
  selector: 'app-common-page-header-delegate',
  template: `
    <ng-container>
      <ng-template></ng-template>
    </ng-container>
  `,
  standalone: true,
})
export class CommonPageHeaderDelegateComponent implements AfterContentInit, OnDestroy, OnInit {
  @Input() entityName?: string;
  @Input() onDemandCountMethod?: string;
  @Output() onPageEvents: EventEmitter<ICommonPageEvent> = new EventEmitter<ICommonPageEvent>();
  @ContentChildren(PrimeTemplate) protected templates?: QueryList<PrimeTemplate>;

  constructor(public commonPageService: CommonPageService) {
    subscriptions().add(
      this.commonPageService.events.subscribe((e) => this.onPageEvents.emit(e))
    );
  }

  ngOnInit(): void {
    if (this.entityName || this.onDemandCountMethod) {
      this.commonPageService.dispatchEvent(new PageTitleEvent(this.entityName!, this.onDemandCountMethod));
    }
  }


  ngAfterContentInit(): void {
    const tplHeader: ICommonPageHeaderTemplates = {};
    (this.templates || []).forEach((tpl) => {
      if (COMMON_PAGE_HEADER_TEMPLATES[tpl.getType()]) {
        tplHeader[COMMON_PAGE_HEADER_TEMPLATES[tpl.getType()]] = tpl.template;
      }
    });
    this.commonPageService.dispatchEvent(new PageHeaderTemplateEvent(tplHeader));
  }

  ngOnDestroy(): void {
    this.commonPageService.dispatchEvent(new PageHeaderTemplateEvent({}));
  }

}
